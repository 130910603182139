import React, {useEffect, useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";

import companyAvatar from "../../../assets/images/company-avatar.png";
import settingsWheelWhite from "../../../assets/svg/settings-wheel-white.svg";
import settingsWheelDark from "../../../assets/svg/settings-wheel-dark.svg";
import verifiedIcon from "../../../assets/svg/verified-icon.svg";
import diamondIcon from "../../../assets/svg/diamond.svg";

import cs from "classnames";

import "./CurrentPlan.scss";
import {InfoOutlined} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import WarningModal from "../../shared/WarningModal/WarningModal";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../firebaseConfig";

const CurrentPlan = () => {
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const location = useLocation();
  const {t} = useTranslation("common");
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (openInfoModal) {
      logEvent(analytics, "need_to_subscribe_modal_opened");
    }
  }, [openInfoModal]);


  const modalTitle = authCtx.isOrganizationBusinessPlusOrPro
    ? t("asSubscriber")
    : !authCtx.isOrganizationCreatedMonthAgo
      ? t("youCanPostForFreeFirstMonth")
      : t("contactSalesForSubscription")


  const getDaysUntilOneMonthOld = (createdAt: string): number => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const oneMonthLater = new Date(createdDate);
    oneMonthLater.setMonth(createdDate.getMonth() + 1);

    const timeDiff = oneMonthLater.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysDiff;
  };

  return (
    <div className="CurrentPlan">

      <div
        className={cs(
          "CurrentPlan__head",
          authCtx.localOrganization.is_pro && "CurrentPlan__head-pro",
          authCtx.localOrganization.is_business_plus && "CurrentPlan__head-business-plus",
          !authCtx.isOrganizationCreatedMonthAgo && "CurrentPlan__head-trial"
        )}
        onClick={() => {
          setOpenInfoModal(true);
        }}
      >
        <div className="CurrentPlan__image-container">

          <img
            alt="pic"
            src={diamondIcon}
            className="CurrentPlan__image"
          />
        </div>

        <h5
          className={cs(
            "CurrentPlan__name",
            (authCtx.isOrganizationBusinessPlusOrPro || !authCtx.isOrganizationCreatedMonthAgo) &&
            "CurrentPlan__name-active",
          )}
        >
          {authCtx.localOrganization.is_pro ?
            "Pro"
            : authCtx.localOrganization.is_business_plus ?
              "Business Plus"
              : !authCtx.isOrganizationCreatedMonthAgo ?
                t("firstMonthFree")
                : t("subscribe")
          }
          {!authCtx.isOrganizationCreatedMonthAgo && !authCtx.isOrganizationBusinessPlusOrPro && (
            <span
              className="CurrentPlan__subtext">{getDaysUntilOneMonthOld(authCtx.localOrganization.created_at)} {t("daysLeft")}</span>
          )}

        </h5>

      </div>
      {openInfoModal && (
        <WarningModal
          title={modalTitle}
          buttonLabel={t("continue")}
          onCancel={() => setOpenInfoModal(false)}
          onSubmit={() => setOpenInfoModal(false)}
          hideCancelBtn={true}
        />
      )}
    </div>
  );
};

export default CurrentPlan;
