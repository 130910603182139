import {useFormik} from "formik";
import * as Yup from "yup";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const useAddEducationFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const {t} = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      schoolName: "",
      degree: "",
      degreeStatus: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: null,
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      schoolName: Yup.string().required(
        memoizedT("schoolNameIsRequired"),
      ),
      degree: Yup.string().required(
        memoizedT("degreeIsRequired"),
      ),
      fieldOfStudy: Yup.string().required(
        memoizedT("fieldOfStudyIsRequired"),
      ),
      startDate: Yup.string().required(
        memoizedT("startDateIsRequired"),
      ),
      // endDate: Yup.string().required(
      //   memoizedT("addEndDate"),
      // ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
