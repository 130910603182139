import {useFormik} from "formik";
import * as Yup from "yup";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const usePublicApplyFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const {t} = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      haveDrivingLicense: false,
      gender: "",
      address: "",
      phoneNumber: "",
      dateOfBirth: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      // companyNumber: Yup.number()
      //   .required(
      //     memoizedT("company.editCompanySettings.companyNumber.required"),
      //   )
      //   .typeError(
      //     memoizedT("company.editCompanySettings.companyNumber.numberError"),
      //   ),
      firstName: Yup.string().required(
        memoizedT("pleaseAddFirstName"),
      ),
      lastName: Yup.string().required(
        memoizedT("pleaseAddLastName"),
      ),
      email: Yup.string().required(
        memoizedT("pleaseAddEmail"),
      ),
      haveDrivingLicense: Yup.boolean().required(
        memoizedT("pleaseAddDrivingLicense"),
      ),
      gender: Yup.string().required(
        memoizedT("pleaseAddGender"),
      ),
      address: Yup.string().required(
        memoizedT("pleaseAddAddress"),
      ),
      phoneNumber: Yup.string().required(
        memoizedT("pleaseAddPhoneNumber"),
      ),
      dateOfBirth: Yup.string().required(
        memoizedT("pleaseAddDateOfBirth"),
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
