import React, {useState} from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";
import peakJobsIcon from "../../../assets/svg/peak-jobs-icon.svg";

import "./LoginModal.scss";
import {useTranslation} from "react-i18next";
import cs from "classnames";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import {GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import googleIcon from "../../../assets/images/google-icon.png";
import InputField from "../InputField/InputField";
import eyeSlash from "../../../assets/svg/eye-slash.svg";
import eye from "../../../assets/svg/eye.svg";
import {useSignInFormik} from "../../../pages/SignIn/SignInForm/useSignInFormik";
import {auth} from "../../../firebaseConfig";
import {apiCall, apiCallWithoutAuth} from "../../../api/Api";
import {handleErrors} from "../../../lib/helpers/handleErrors";
import { on } from "events";

interface LoginModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  title?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  onEmailIsNotVerified: () => void;
  onUserHasNoProfile: () => void;
}

const LoginModal = (props: LoginModalProps) => {
  const {t} = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const globalCtx = useGlobalContext();
  const authCtx = useAuthContext();
  const provider = new GoogleAuthProvider();


  const formik = useSignInFormik({
    onSubmit: async (values: any) => {
    // console.log("submitting form");
      try {
        setIsLoading(true);
        //SIGN IN USER
        const userCredential = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );
        //IF EMAIL IS NOT VERIFIED
        if (!userCredential.user.emailVerified) {
          //VERIFY EMAIL
          await apiCallWithoutAuth("post", "b2c/user/verify-email/", {
            email: userCredential.user.email,
          });
          //PROCEED
          props.onEmailIsNotVerified();
          return;
        } 
        console.log("userCredential", userCredential);
        // GET USER PROFILE
        let profileData = await apiCall("GET", "b2c/user/");
        console.log("profileData", profileData);

        //IF PHONE NUMBER IS NOT VERIFIED
        // if (profileData?.data?.user?.phone_number === null) {
        //   navigate("/verify-phone-number");
        //   return;
        // }

        //IF PROFILE IS NOT CREATED
        if (profileData.data.profile === null) {
          props.onUserHasNoProfile();
          return;
        }

        props.onSubmit();
      } catch (error: any) {
        console.log("error", error);
        const errorMessage = handleErrors(error, t);
        globalCtx.openMessageModal("error", errorMessage);
        authCtx.signOutFirebaseUser();
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleContinueWithGoogle = async () => {
    try {
      setIsLoadingGoogle(true);
      //SIGN IN WITH GOOGLE
      const userCredential = await signInWithPopup(auth, provider);

      //IF EMAIL IS NOT VERIFIED
      if (!userCredential.user.emailVerified) {
        //VERIFY EMAIL
        await apiCallWithoutAuth("post", "b2c/user/verify-email/", {
          email: userCredential.user.email,
        });
        //PROCEED
        props.onEmailIsNotVerified();
        return;
      } 
      console.log("userCredential", userCredential);
      // GET USER PROFILE
      let profileData = await apiCall("GET", "b2c/user/");
      console.log("profileData", profileData);

      //IF PHONE NUMBER IS NOT VERIFIED
      // if (profileData?.data?.user?.phone_number === null) {
      //   navigate("/verify-phone-number");
      //   return;
      // }

      //IF PROFILE IS NOT CREATED
      if (profileData.data.profile === null) {
        props.onUserHasNoProfile();
        return;
      }

      props.onSubmit();

    } catch (error: any) {
      console.log("error", error);
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
      authCtx.signOutFirebaseUser();
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  return (
    <div className="LoginModal">
      <div className="LoginModal__container">
        <img
          className="LoginModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={peakJobsIcon} alt="alert icon" className="LoginModal__icon"/>

        <div className="SignInForm__container">
          <h2 className="SignInForm__title">{t("signIn.form.title")}</h2>
          <div className="SignInForm__google">
            <div
              className="SignInForm__google-container"
              onClick={() => {
                handleContinueWithGoogle();
              }}
            >
              {isLoadingGoogle ? (
                <div className="SignInForm__google-spinner"></div>
              ) : (
                <>
                  <img
                    alt="pic"
                    src={googleIcon}
                    className="SignInForm__google-icon"
                  />
                  <div className="SignInForm__google-text">
                    {t("signIn.form.googleText")} Google
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="SignInForm__or">{t("signIn.form.or")}</div>
          <div className="SignInForm__input">
            <InputField
              type="text"
              placeholder={t("signIn.form.email.placeholder")}
              name="email"
              value={formik.values.email}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
            />
            {formik.errors.email && formik.touched.email && (
              <h5 className="SignInForm__input-error">
                {formik.errors.email as string}
              </h5>
            )}
          </div>
          <div className="SignInForm__input">
            <InputField
              type={showPassword ? "text" : "password"}
              placeholder={t("signIn.form.password.placeholder")}
              name="password"
              value={formik.values.password}
              onBlur={(e: any) => formik.handleBlur(e)}
              onChange={(e: any) => formik.handleChange(e)}
              onIconClick={() => {
                setShowPassword(!showPassword);
              }}
              icon={showPassword ? eyeSlash : eye}
            />
            {formik.errors.password && formik.touched.password && (
              <h5 className="SignInForm__input-error">
                {formik.errors.password as string}
              </h5>
            )}
          </div>
          <h5
            className="SignInForm__forgotPassword"
            onClick={() => {
              navigate("/reset-password");
            }}
          >
            {t("signIn.form.forgotPassword")}
          </h5>
          <div className="SignInForm__button">
            <Button
              isLoading={isLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
              label={t("signIn.form.button")}
              type="submit"
            />
          </div>
          <div className="SignInForm__link">
            <p className="SignInForm__link-text">
              {t("signIn.form.registerLink.registerLinkOne")}{" "}
              <span
                onClick={() => {
                  props.onCancel();
                }}
              >
              {t("applyWithoutAccount")}
            </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
