import React from "react";
import cs from "classnames";

import "./Button.scss";

interface ButtonProps {
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick: (event: any) => void;
  type?: "submit" | "reset" | "button";
  form?: string;
  isLoading?: boolean;
  id?: string;
  disabled?: boolean;
  imgSrc?: string;
}

const Button = (props: ButtonProps) => {
  const { disabled, isLoading, label, style, className, type, id, imgSrc } = props;

  return (
    <button
      disabled={disabled}
      type={type}
      id={id}
      onClick={(event) => {
        props.onClick(event);
      }}
      style={style}
      className={cs("Button", className, disabled && `Button--disabled`)}
    >
      {isLoading ? (
        <div className="Button__spinner" />
      ) : (
        <>
          {imgSrc && <img src={imgSrc} alt="button icon" className="Button__img" />}
          {label && <h4 className="Button__label">{label}</h4>}
        </>
      )}
    </button>
  );
};

export default Button;
