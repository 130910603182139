import {useFormik} from "formik";
import * as Yup from "yup";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const useAddExperienceFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const {t} = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      companyName: "",
      jobTitle: "",
      startDate: "",
      endDate: null,
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      companyName: Yup.string().required(
        memoizedT("companyNameIsRequired"),
      ),
      jobTitle: Yup.string().required(
        memoizedT("jobTitleIsRequired"),
      ),
      startDate: Yup.string().required(
        memoizedT("startDateIsRequired"),
      ),
      // endDate: Yup.string().required(
      //   memoizedT("addEndDate"),
      // ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
