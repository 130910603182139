import React from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./AddExperienceModal.scss";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import AddEducationForm from "./AddEducationForm/AddExperienceForm";

interface AddExperienceModalProps {
  onCancel: () => void;
  onSubmit: (values: any) => void;
  title?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
}

const AddExperienceModal = (props: AddExperienceModalProps) => {
  const { t } = useTranslation("common");

  return (
    <div className="AddExperienceModal">
      <div className="AddExperienceModal__container">
        {/*<img*/}
        {/*  className="AddExperienceModal__close"*/}
        {/*  alt="close icon"*/}
        {/*  src={closeIcon}*/}
        {/*  onClick={() => {*/}
        {/*    props.onCancel();*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<img src={ErrorIcon} alt="alert icon" className="AddExperienceModal__icon" />*/}
        <AddEducationForm
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
        />
      </div>
    </div>
  );
};

export default AddExperienceModal;
