import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useAddExperienceFormik} from "./useAddExperienceFormik";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import InputField from "../../InputField/InputField";

import "./AddExperienceForm.scss";
import {Checkbox} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Button from "../../Button/Button";
import cs from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/sq";

interface AddExperienceFormProps {
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const AddExperienceForm = (props: AddExperienceFormProps) => {
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [stillWorking, setStillWorking] = useState(false);
  

  const formik = useAddExperienceFormik({
    onSubmit: async (values: any) => {
      console.log("Form submitted:", values);

      const data = {
        company_name: values.companyName,
        job_title: values.jobTitle,
        start_date: values.startDate,
        end_date: values.endDate === "" ? null : values.endDate,
      };
      props.onSubmit(data);
    },
  });
  return (
    <>
      <div className="AddExperienceForm">
        {/*<p className="AddExperienceForm__description">*/}
        {/*  {t("fillTheFormToAddExperience")}*/}
        {/*</p>*/}
        <div className="AddExperienceForm__body">
          <div className="AddExperienceDetails">
            <div className="AddExperienceDetails__body">
              <div className="AddExperienceDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "companyName",
                  )}
                  name="companyName"
                  value={formik.values.companyName}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="AddExperienceDetails__input-description">
                  {t(
                    "addCompanyName",
                  )}
                </div>
                {formik.errors.companyName &&
                  formik.touched.companyName && (
                    <h5 className="AddExperienceDetails__input-error">
                      {formik.errors.companyName as string}
                    </h5>
                  )}
              </div>
              {" "}
              <div className="AddExperienceDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "position",
                  )}
                  name="jobTitle"
                  value={formik.values.jobTitle}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="AddExperienceDetails__input-description">
                  {t(
                    "addPosition",
                  )}
                </div>
                {formik.errors.jobTitle &&
                  formik.touched.jobTitle && (
                    <h5 className="AddExperienceDetails__input-error">
                      {formik.errors.jobTitle as string}
                    </h5>
                  )}
              </div>
              {" "}

              <div className="AddExperienceDetails__input">
                <div className="AddExperienceDetails__input-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
                    <DatePicker
                      className="InputField__container"
                      onChange={(newValue) => formik.setFieldValue('startDate', dayjs(newValue).format('YYYY-MM-DD'))}
                      slotProps={{textField: {placeholder: t("startDate")}}}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '100%',
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          padding: '0 20px',
                          height: '71px',
                          fontSize: '16px',
                          fontFamily: '"Manrope-Bold", sans-serif',
                          textAlign: 'left'
                        },
                      }}
                    />
                  </LocalizationProvider>

                  {!stillWorking && (

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
                      <DatePicker
                        className="InputField__container"
                        onChange={(newValue) => formik.setFieldValue('endDate', dayjs(newValue).format('YYYY-MM-DD'))}
                        slotProps={{textField: {placeholder: t("endDate")}}}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            width: '100%',
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            padding: '0 20px',
                            height: '71px',
                            fontSize: '16px',
                            fontFamily: '"Manrope-Bold", sans-serif',
                            textAlign: 'left'
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}

                </div>
                <div className="AddExperienceDetails__input-checkbox-description">
                  {t(
                    "stillWorkingHere"
                  )}
                  <Checkbox
                    checked={stillWorking}
                    onChange={(e) => {
                      setStillWorking(e.target.checked);
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'black',
                      },
                      '& .MuiCheckbox-root': {
                        color: 'black',
                      },
                    }}
                  />
                </div>

                <div className="AddExperienceDetails__input-description">
                  {t(
                    "addStartAndEndDate",
                  )}
                </div>
                {formik.errors.startDate && formik.touched.startDate && (
                  <h5 className="AddExperienceDetails__input-error">
                    {formik.errors.startDate as string}
                  </h5>
                )}
                {formik.errors.endDate && formik.touched.endDate && (
                  <h5 className="AddExperienceDetails__input-error">
                    {formik.errors.endDate as string}
                  </h5>
                )}
              </div>
            </div>
            <div className="AddExperienceModal__buttons">
              <Button
                type="reset"
                style={{height: "60px", marginRight: "5px"}}
                className={cs("Button__secondary AddExperienceModal__cancel-btn")}
                label={t("cancel")}
                onClick={() => {
                  // props.onCancel();
                  props.onCancel();
                }}
              />
              <Button
                type="submit"
                style={{height: "60px", marginLeft: "5px"}}
                label={t("save")}
                onClick={() => {
                  // props.onSubmit();
                  formik.handleSubmit();
                }}
                className="AddExperienceModal__submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExperienceForm;
