import React, { useEffect, useState } from "react";

import { auth } from "../../firebaseConfig";
import { useTranslation } from "react-i18next";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { apiCallWithoutAuth } from "../../api/Api";
import { useGlobalContext } from "../../context/GlobalContext/GlobalContext";
import { useNavigate } from "react-router-dom";
import { convertRestApiErrorCodes } from "../../lib/helpers/handleErrors";
import Button from "../../components/shared/Button/Button";

import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";
import envelope from "../../assets/svg/envelope.svg";

import cs from "classnames";

import { onAuthStateChanged, User } from "firebase/auth";

import "./SendEmail.scss";

const SendEmail = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const isMobile = useResponsiveDimensions().isMobile;
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    setSeconds(59);
  }, []);

  useEffect(() => {
    if (seconds === 60) {
      return;
    }
    if (seconds < 60) {
      setIsButtonDisabled(true);

      seconds > 0 && setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 0) {
      setIsButtonDisabled(false);
      return;
    }
  }, [seconds]);

  const handleResendEmail = async (email: string | null | undefined) => {
    try {
      setIsLoading(true);
      //VERIFY EMAIL
      await apiCallWithoutAuth("post", "b2c/user/verify-email/", {
        email: email,
      });
      globalCtx.openMessageModal(
        "success",
        t("staticMessage.resendEmailSuccess"),
      );
    } catch (error: any) {
      const errorMessage = convertRestApiErrorCodes(
        error?.response?.data?.code,
        t,
      );
      globalCtx.openMessageModal("error", errorMessage);
    } finally {
      setIsLoading(false);
      setSeconds(59);
    }
  };

  return (
    <div className="SendEmail">
      <div
        className={cs("SendEmail__left", isMobile && "SendEmail__left-mobile")}
      >
        <div className="SendEmail__left-container">
          <div className="SendEmail__logo">
            <img
              alt="pic"
              src={peakJobsLogo}
              className="SendEmail__logo-icon"
            />
          </div>
          <div className="SendEmail__form">
            <div className="SendEmail__form-container">
              <div className="SendEmail__form-title">
                <img
                  alt="pic"
                  src={envelope}
                  className="SendEmail__form-title--icon"
                />
                <h2 className="SendEmail__form-title--text">
                  {t("sendEmail.form.title")}
                </h2>
              </div>
              <p className="SendEmail__form-descriptionOne">
                {t("sendEmail.form.descriptionOne")}
                <span>{user?.email}</span>
              </p>{" "}
              <p className="SendEmail__form-descriptionTwo">
                {t("sendEmail.form.descriptionTwo")}{" "}
                <span>{t("sendEmail.form.descriptionThree")}</span>
              </p>
              <Button
                label={
                  isButtonDisabled
                    ? `${t("sendEmail.form.button")} ...${seconds}s`
                    : `${t("sendEmail.form.button")}`
                }
                disabled={isButtonDisabled}
                isLoading={isLoading}
                onClick={() => {
                  handleResendEmail(user?.email);
                }}
              />
              <p
                className="SendEmail__form-back"
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                {t("backToSignIn")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="SendEmail__right">
          <div className="SendEmail__right-container">
            <div className="SendEmail__bigCircle">
              <div className="SendEmail__smallCircle">
                <p className="SendEmail__smallCircle-text">
                  <span> +300</span> <br /> {t("sendEmail.circle.partOne")}{" "}
                  <br /> {t("sendEmail.circle.partTwo")} <br />
                  {t("sendEmail.circle.partThree")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendEmail;
