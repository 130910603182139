import React from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/shared/Button/Button";

import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  return (
    <div className="NotFound">
      <div className="NotFound__container">
        <div className="NotFound__number">404</div>
        <div className="NotFound__text">{t("notFound.text")}</div>
        <Button
          onClick={() => {
            window.location.href = "https://peakjobs.app";
          }}
          label={t("notFound.button")}
        />
      </div>
    </div>
  );
};

export default NotFound;
