import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useAddEducationFormik} from "./useAddEducationFormik";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import InputField from "../../InputField/InputField";

import "./AddEducationForm.scss";
import {Checkbox, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Button from "../../Button/Button";
import cs from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/sq";

interface AddEducationFormProps {
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

interface DegreeOption {
  value: string;
  label: string;
}

const AddEducationForm = (props: AddEducationFormProps) => {
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [stillStudying, setStillStudying] = useState(false);

  const degreeOptions: DegreeOption[] = [
    {value: "elementary", label: t("elementarySchoolDegree")},
    {value: "high_school", label: t("highSchoolDegree")},
    {value: "bachelor", label: t("bachelorDegree")},
    {value: "master", label: t("masterDegree")},
    {value: "doctorate", label: t("doctoralDegree")},
  ];

  const formik = useAddEducationFormik({
    onSubmit: async (values: any) => {
      console.log("Form submitted:", values);

      const data = {
        school_name: values.schoolName,
        degree: values.degree,
        field_of_study: values.fieldOfStudy,
        start_date: values.startDate,
        end_date: values.endDate === "" ? null : values.endDate,
        degree_status: values.degreeStatus,
      };
      props.onSubmit(data);
    },
  });
  return (
    <>
      <div className="AddEducationForm">
        {/*<p className="AddEducationForm__description">*/}
        {/*  {t("fillTheFormToAddEducation")}*/}
        {/*</p>*/}
        <div className="AddEducationForm__body">
          <div className="AddEducationDetails">
            <div className="AddEducationDetails__body">
              <div className="AddEducationDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "schoolName",
                  )}
                  name="schoolName"
                  value={formik.values.schoolName}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="AddEducationDetails__input-description">
                  {t(
                    "addSchoolName",
                  )}
                </div>
                {formik.errors.schoolName &&
                  formik.touched.schoolName && (
                    <h5 className="AddEducationDetails__input-error">
                      {formik.errors.schoolName as string}
                    </h5>
                  )}
              </div>
              {" "}
              <div className="AddEducationDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "fieldOfStudy",
                  )}
                  name="fieldOfStudy"
                  value={formik.values.fieldOfStudy}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="AddEducationDetails__input-description">
                  {t(
                    "addFieldOfStudy",
                  )}
                </div>
                {formik.errors.fieldOfStudy &&
                  formik.touched.fieldOfStudy && (
                    <h5 className="AddEducationDetails__input-error">
                      {formik.errors.fieldOfStudy as string}
                    </h5>
                  )}
              </div>
              <div className="AddEducationDetails__dropdown-input">
                <FormControl fullWidth className="InputField__container">
                  <Select
                    id="degree-select"
                    name="degreeStatus" // Add a name attribute
                    value={formik.values.degreeStatus || ""}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      const selectedOption = degreeOptions.find(
                        (option) => option.value === selectedValue
                      );
                      formik.setFieldValue(event.target.name, selectedValue); // Set degreeStatus
                      formik.setFieldValue("degree", selectedOption?.label || ""); // Set the translated label
                    }}
                    variant="standard"
                    fullWidth
                    disableUnderline
                    displayEmpty
                    sx={{
                      fontFamily: '"Manrope-Bold", sans-serif',
                      backgroundColor: 'white',
                      border: 'none',
                      boxShadow: 'none',
                      padding: '0px 0px 0px 16px',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                      '&:focus': {
                        backgroundColor: 'white',
                        outline: 'none',
                      },
                      '& .MuiSelect-icon': {
                        marginRight: '10px',
                      },
                      '& .MuiSelect-select': {
                        backgroundColor: 'white',
                        color: formik.values.degreeStatus ? 'black' : '#b2b2b2',
                      },
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {t("degree")}
                    </MenuItem>
                    {degreeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {formik.errors.degree &&
                formik.touched.degree && (
                  <h5 className="AddEducationDetails__input-error">
                    {formik.errors.degree as string}
                  </h5>
                )}
              {" "}

              <div className="AddEducationDetails__input">
                <div className="AddEducationDetails__input-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
                    <DatePicker
                      className="InputField__container"
                      onChange={(newValue) => formik.setFieldValue('startDate', dayjs(newValue).format('YYYY-MM-DD'))}
                      slotProps={{textField: {placeholder: t("startDate")}}}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          width: '100%',
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                        '& .MuiInputBase-input': {
                          padding: '0 20px',
                          height: '71px',
                          fontSize: '16px',
                          fontFamily: '"Manrope-Bold", sans-serif',
                          textAlign: 'left'
                        },
                      }}
                    />
                  </LocalizationProvider>

                  {!stillStudying && (

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
                      <DatePicker
                        className="InputField__container"
                        onChange={(newValue) => formik.setFieldValue('endDate', dayjs(newValue).format('YYYY-MM-DD'))}
                        slotProps={{textField: {placeholder: t("endDate")}}}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            width: '100%',
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputBase-input': {
                            padding: '0 20px',
                            height: '71px',
                            fontSize: '16px',
                            fontFamily: '"Manrope-Bold", sans-serif',
                            textAlign: 'left'
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}

                </div>
                <div className="AddEducationDetails__input-checkbox-description">
                  {t(
                    "stillStudyingHere"
                  )}
                  <Checkbox
                    checked={stillStudying}
                    onChange={(e) => {
                      setStillStudying(e.target.checked);
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'black',
                      },
                      '& .MuiCheckbox-root': {
                        color: 'black',
                      },
                    }}
                  />
                </div>

                <div className="AddEducationDetails__input-description">
                  {t(
                    "addStartAndEndDate",
                  )}
                </div>
                {formik.errors.startDate && formik.touched.startDate && (
                  <h5 className="AddEducationDetails__input-error">
                    {formik.errors.startDate as string}
                  </h5>
                )}
                {formik.errors.endDate && formik.touched.endDate && (
                  <h5 className="AddEducationDetails__input-error">
                    {formik.errors.endDate as string}
                  </h5>
                )}
              </div>
            </div>
            <div className="AddEducationModal__buttons">
              <Button
                type="reset"
                style={{height: "60px", marginRight: "5px"}}
                className={cs("Button__secondary AddEducationModal__cancel-btn")}
                label={t("cancel")}
                onClick={() => {
                  // props.onCancel();
                  props.onCancel();
                }}
              />
              <Button
                type="submit"
                style={{height: "60px", marginLeft: "5px"}}
                label={t("save")}
                onClick={() => {
                  // props.onSubmit();
                  formik.handleSubmit();
                }}
                className="AddEducationModal__submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEducationForm;
